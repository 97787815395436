// Header menus data =================================================================
import CanvasColoredIcon from "static/svg/header/workcanvas_colored.svg";
import WorkformsColoredIcon from "static/svg/header/workforms_colored.svg";
import MarketingColoredIcon from "static/svg/header/marketing_colored.svg";
import DevColoredIcon from "static/svg/header/dev_colored.svg";
import ITColoredIcon from "static/svg/header/it_colored_1.svg";
import OperationsColoredIcon from "static/svg/header/operations_colored.svg";
import SalesColoredIcon from "static/svg/header/sales_colored_1.svg";
import HRColoredIcon from "static/svg/header/hr_colored_1.svg";
import EnterpriseColoredIcon from "static/svg/header/enterprise_colored_1.svg";
import RetailColoredIcon from "static/svg/header/retail_colored.svg";
import ConstructionColoredIcon from "static/svg/header/construction_colored.svg";
import MediaColoredIcon from "static/svg/header/media_colored.svg";
import SmbColoredIcon from "static/svg/header/small_business_colored_1.svg";
import NonprofitColoredIcon from "static/svg/header/nonprofit_colored.svg";
// import BoardsColoredIcon from "static/svg/header/boards_colored.svg";
import DocsColoredIcon from "static/svg/header/docs_colored.svg";
import FormsColoredIcon from "static/svg/header/forms_icon_new.svg";
import GanttColoredIcon from "static/svg/header/gantt_colored.svg";
import AIColoredIcon from "static/svg/header/ai-icon.svg";
import KanbanColoredIcon from "static/svg/header/kanban_colored.svg";
import AutomationsColoredIcon from "static/svg/header/automations_colored.svg";
import IntegrationsColoredIcon from "static/svg/header/integrations_colored.svg";
import DashboardsColoredIcon from "static/svg/header/dashboards_colored.svg";
// import EnterpriseAgilityColoredIcon from "static/svg/header/enterprise_agility_colored.svg";
import ProjectManagementColoredIcon from "static/svg/header/project_management_colored_2.svg";
import MondayWMSquareIcon from "static/svg/header/monday_work_management_square.svg";
import MondayCRMSquareIcon from "static/svg/header/monday_crm_square.svg";
import MondayMarketerSquareIcon from "static/svg/header/monday_marketer_square.svg";
import MondayProjectsSquareIcon from "static/svg/header/monday_projects_square.svg";
import MondayDevSquareIcon from "static/svg/header/monday_dev_square.svg";
import WMIcon from "static/svg/header/wm_logo.svg";
import CRMIcon from "static/svg/header/mobile_crm_logo.svg";
import MarketerIcon from "static/svg/header/mobile_marketer_logo.svg";
import ProjectsIcon from "static/svg/header/mobile_projects_logo.svg";
import DevIcon from "static/svg/header/dev_logo_new.svg";
import ServiceIcon from "static/svg/header/service_logo.svg";
// platform icons
import { BACKGROUND_GRAY_COLOR_NAME, BRAND_SERVICE_PRIMARY_COLOR_NAME, CRM_GREEN_COLOR_NAME, DARK_WORK_OS_IRIS_COLOR_NAME, DEV_GREEN_COLOR_NAME, MARKETING_RED_COLOR_NAME, PROJECTS_ORANGE_COLOR_NAME, WHITE_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { ABOUT_US_PAGE_LINK, AI_PAGE_LINK, APPS_DEVELOPER_LINK, AUTOMATIONS_PAGE_LINK, BLOG_PAGE_LINK, BUILDERS_CERTIFICATIONS_PAGE_LINK, CANVAS_HOMEPAGE_LINK, COMMUNITY_LINK, CONSTRUCTION_MINI_SITE_LINK, CRM_MINI_SITE_LINK, CUSTOMERS_PAGE_LINK, DASHBOARDS_PAGE_LINK, ENTERPRISE_PAGE_LINK, FORMS_PAGE_LINK, GANTT_PAGE_LINK, GLOBAL_EVENTS_PAGE_LINK, HELP_CENTER_LINK, HR_USE_CASES_LINK, INTEGRATIONS_PAGE_LINK, KANBAN_PAGE_LINK, MARKETING_MANAGEMENT_MINI_SITE_LINK, MEDIA_PAGE_LINK, MONDAY_SPACES_PAGE_LINK, NGO_PAGE_LINK, OPERATIONS_CLUSTER_LINK, PARTNERS_PAGE_LINK, PROJECT_MANAGEMENT_MINI_SITE_LINK, RETAIL_PAGE_LINK, SERVICE_MINI_SITE_LINK, SMB_PAGE_LINK, SOFTWARE_MINI_SITE_LINK, SUPPORT_PAGE_LINK, TEMPLATE_CENTER_PAGE_LINK, WHATS_NEW_PAGE_LINK, WORK_MANAGEMENT_FOR_MARKETING_MINI_SITE_LINK, WORK_MANAGEMENT_FOR_PMO_MINI_SITE_LINK, WORK_MANAGEMENT_MINI_SITE_LINK, WORKDOCS_PAGE_LINK, WORKFORMS_HOMEPAGE_LINK } from "constants/links";
import { hexToRgba } from "utils/general";
import colors from "styles/colors";
import { isMiniSiteConfigValid } from "client/services/cluster-config-service/cluster-config-service";
export const PRODUCTS_MENU_ID = "products";
export const USE_CASES_MENU_ID = "use-cases";
export const FEATURES_MENU_ID = "features";
export const RESOURCES_MENU_ID = "resources";
// structured header
export const SOLUTIONS_MENU_ID = "solutions";
export const TEAMS_MENU_ID = "teams";
export const PLATFORM_MENU_ID = "platform";
export const CUSTOMERS_MENU_ID = "customers";
export const DESKTOP_HEADER_BASE_CLASS_NAME = "base-header-desktop-component";
export const PRODUCTS_PRODUCTS_SECTION_ID = "products";
export const PRODUCTS_MORE_SECTION_ID = "more";
export const BY_TEAM_SECTION_ID = "by-team";
export const BY_BUSINESS_SIZE_SECTION_ID = "by-business-size";
export const BY_INDUSTRY_SECTION_ID = "by-industry";
export const KEY_FEATURES_SECTION_ID = "key-features";
export const RESOURCES_LEARN_SECTION_ID = "learn";
export const RESOURCES_CONNECT_SECTION_ID = "connect";
export const RESOURCES_EXPLORE_SECTION_ID = "explore";
export const TOP_STORIES_SECTION_ID = "top-stories";
export const ICON_TYPES = {
    fill: "fill",
    outline: "outline"
};
export const MENU_SECTION_CLASSNAMES = {
    products: "products",
    mondayProducts: "mondayProducts",
    moreProducts: "moreProducts",
    useCases: "use-cases",
    features: "features",
    resources: "resources",
    integrations: "integrations",
    addOns: "addOns"
};
export const MENU_SECTION_ITEM_TYPE = {
    base: "base",
    product: "product",
    secondaryProduct: "secondary-product",
    useCase: "use-case",
    useCaseByWorkflow: "use-case-by-workflow",
    feature: "feature",
    resource: "resource",
    story: "story",
    byTeam: "by-team",
    byIndustry: "by-industry",
    innerMenuBottomItem: "inner-menu-bottom-item",
    integration: "integration",
    addOn: "addOn"
};
export const mobileProductsSectionMenuItems = [
    {
        id: PRODUCTS_PRODUCTS_SECTION_ID,
        title: "header.products.section.withWm.text.title",
        subtitle: "header.products.section.withWm.text.subtitle",
        mobileTitle: "header.products.section.products.mobileTitle",
        classname: MENU_SECTION_CLASSNAMES.mondayProducts,
        maxNumberOfItemsInColumn: 3,
        noSeparator: true,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: WMIcon,
                icon: MondayWMSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondayWorkManagement.title",
                subtitle: "header.tabs.products.mondayWorkManagement.subtitle1",
                url: WORK_MANAGEMENT_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[WORK_OS_IRIS_COLOR_NAME], 0.16)
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: CRMIcon,
                icon: MondayCRMSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondaySalesCRM.title",
                subtitle: "header.tabs.products.mondaySalesCRM.subtitle",
                url: CRM_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
                tags: {
                    title: "header.tabs.products.tags.title",
                    color: "#025050",
                    backgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
                    list: [
                        "header.tabs.products.mondaySalesCRM.tags.contactManagement",
                        "header.tabs.products.mondaySalesCRM.tags.salesPipeline",
                        "header.tabs.products.mondaySalesCRM.tags.leadManagement"
                    ]
                }
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: DevIcon,
                icon: MondayDevSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondayDev.title",
                subtitle: "header.tabs.products.mondayDev.subtitle1",
                url: SOFTWARE_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.16)
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: ServiceIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondayService.title",
                subtitle: "header.tabs.products.mondayService.subtitle",
                url: SERVICE_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[BRAND_SERVICE_PRIMARY_COLOR_NAME], 0.16),
                hasBetaTag: true
            }
        ]
    },
    {
        id: PRODUCTS_MORE_SECTION_ID,
        classname: MENU_SECTION_CLASSNAMES.moreProducts,
        title: "header.products.section.more",
        titleLine: true,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
                icon: WorkformsColoredIcon,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.more.workForms.title",
                url: WORKFORMS_HOMEPAGE_LINK,
                openInNewTab: true
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
                icon: CanvasColoredIcon,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.more.workCanvas.title",
                url: CANVAS_HOMEPAGE_LINK,
                openInNewTab: true
            }
        ]
    }
];
const productsSectionMenuItems = [
    {
        id: PRODUCTS_PRODUCTS_SECTION_ID,
        title: "header.products.section.withWm.text.title",
        mobileTitle: "header.products.section.products.mobileTitle",
        classname: MENU_SECTION_CLASSNAMES.mondayProducts,
        maxNumberOfItemsInColumn: 3,
        noSeparator: true,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: CRMIcon,
                icon: MondayWMSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondayWorkManagement.title",
                subtitle: "header.tabs.products.mondayWorkManagement.subtitle1",
                url: WORK_MANAGEMENT_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[WORK_OS_IRIS_COLOR_NAME], 0.16),
                tags: {
                    title: "header.tabs.products.tags.title",
                    color: colors[DARK_WORK_OS_IRIS_COLOR_NAME],
                    backgroundColor: hexToRgba("#babafd", 0.2),
                    list: [
                        "header.tabs.products.mondayWorkManagement.tags.projectAndTaskManagement",
                        "header.tabs.products.mondayWorkManagement.tags.operations",
                        "header.tabs.products.mondayWorkManagement.tags.buildYourOwn"
                    ]
                }
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: CRMIcon,
                icon: MondayCRMSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondaySalesCRM.title",
                subtitle: "header.tabs.products.mondaySalesCRM.subtitle",
                url: CRM_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
                tags: {
                    title: "header.tabs.products.tags.title",
                    color: "#025050",
                    backgroundColor: hexToRgba(colors[CRM_GREEN_COLOR_NAME], 0.16),
                    list: [
                        "header.tabs.products.mondaySalesCRM.tags.contactManagement",
                        "header.tabs.products.mondaySalesCRM.tags.salesPipeline",
                        "header.tabs.products.mondaySalesCRM.tags.leadManagement"
                    ]
                }
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: DevIcon,
                icon: MondayDevSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondayDev.title",
                subtitle: "header.tabs.products.mondayDev.subtitle1",
                url: SOFTWARE_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.16),
                tags: {
                    title: "header.tabs.products.tags.title",
                    color: "#015727",
                    backgroundColor: hexToRgba(colors[DEV_GREEN_COLOR_NAME], 0.08),
                    list: [
                        "header.tabs.products.mondayDev.tags.roadmapPlanning",
                        "header.tabs.products.mondayDev.tags.bugTracking",
                        "header.tabs.products.mondayDev.tags.scrumAndKanban"
                    ]
                }
            }
        ]
    },
    {
        id: PRODUCTS_PRODUCTS_SECTION_ID,
        mobileTitle: "header.products.section.products.mobileTitle",
        classname: MENU_SECTION_CLASSNAMES.mondayProducts,
        maxNumberOfItemsInColumn: 3,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: MarketerIcon,
                icon: MondayMarketerSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondayMarketer.title",
                subtitle: "header.tabs.products.mondayMarketer.subtitle",
                url: MARKETING_MANAGEMENT_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[MARKETING_RED_COLOR_NAME], 0.16),
                tags: {
                    title: "header.tabs.products.tags.title",
                    color: "#9A0550",
                    backgroundColor: hexToRgba(colors[MARKETING_RED_COLOR_NAME], 0.08),
                    list: [
                        "header.tabs.products.mondayMarketer.tags.contentCalendar",
                        "header.tabs.products.mondayMarketer.tags.marketingStrategy",
                        "header.tabs.products.mondayMarketer.tags.creativeRequests"
                    ]
                }
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.product,
                mobileIcon: ProjectsIcon,
                icon: MondayProjectsSquareIcon,
                iconStrokeColor: colors.gainsboro,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.mondayProjects.title",
                subtitle: "header.tabs.products.mondayProjects.subtitle",
                url: PROJECT_MANAGEMENT_MINI_SITE_LINK,
                hoverBackgroundColor: hexToRgba(colors[PROJECTS_ORANGE_COLOR_NAME], 0.16),
                tags: {
                    title: "header.tabs.products.tags.title",
                    color: "#BC5004",
                    backgroundColor: hexToRgba(colors[PROJECTS_ORANGE_COLOR_NAME], 0.08),
                    list: [
                        "header.tabs.products.mondayProjects.tags.advancedProjects",
                        "header.tabs.products.mondayProjects.tags.portfolioManagement",
                        "header.tabs.products.mondayProjects.tags.criticalPath"
                    ]
                }
            }
        ]
    },
    {
        id: PRODUCTS_MORE_SECTION_ID,
        classname: MENU_SECTION_CLASSNAMES.moreProducts,
        title: "header.products.section.more",
        titleLine: true,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
                icon: WorkformsColoredIcon,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.more.workForms.title",
                url: WORKFORMS_HOMEPAGE_LINK,
                openInNewTab: true
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.secondaryProduct,
                icon: CanvasColoredIcon,
                iconType: ICON_TYPES.fill,
                title: "header.tabs.products.more.workCanvas.title",
                url: CANVAS_HOMEPAGE_LINK,
                openInNewTab: true
            }
        ]
    }
];
const useCasesSectionMenuItems = [
    {
        id: BY_TEAM_SECTION_ID,
        title: "header.useCases.section.byTeam",
        titleLine: true,
        classname: MENU_SECTION_CLASSNAMES.useCases,
        maxNumberOfItemsInColumn: 4,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: MarketingColoredIcon,
                iconColor: MARKETING_RED_COLOR_NAME,
                title: "header.tabs.workflows.byTeam.marketing",
                url: WORK_MANAGEMENT_FOR_MARKETING_MINI_SITE_LINK,
                outline: true
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCaseByWorkflow,
                icon: ProjectManagementColoredIcon,
                title: "header.tabs.workflows.byWorkflow.projectManagement.title",
                description: "header.tabs.workflows.byWorkflow.projectManagement.description",
                url: WORK_MANAGEMENT_FOR_PMO_MINI_SITE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: SalesColoredIcon,
                iconColor: CRM_GREEN_COLOR_NAME,
                title: "header.tabs.workflows.byTeam.sales",
                url: CRM_MINI_SITE_LINK,
                outline: true
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: DevColoredIcon,
                iconColor: DEV_GREEN_COLOR_NAME,
                title: "header.tabs.teams.byTeam.productDevelopers",
                url: SOFTWARE_MINI_SITE_LINK,
                outline: true
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: OperationsColoredIcon,
                title: "header.tabs.workflows.byTeam.operations",
                url: OPERATIONS_CLUSTER_LINK,
                outline: true
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: ITColoredIcon,
                title: "header.tabs.workflows.byTeam.it",
                url: SERVICE_MINI_SITE_LINK,
                outline: true
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: HRColoredIcon,
                title: "header.tabs.workflows.byTeam.hr",
                url: HR_USE_CASES_LINK,
                outline: true
            }
        ]
    },
    {
        id: BY_BUSINESS_SIZE_SECTION_ID,
        title: "header.useCases.section.byBusinessSize",
        titleLine: true,
        classname: MENU_SECTION_CLASSNAMES.useCases,
        maxNumberOfItemsInColumn: 4,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: EnterpriseColoredIcon,
                title: "header.tabs.workflows.byBusinessSize.enterprise",
                url: ENTERPRISE_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: SmbColoredIcon,
                title: "header.tabs.workflows.byBusinessSize.smallBusiness",
                url: SMB_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.useCase,
                icon: NonprofitColoredIcon,
                title: "header.tabs.workflows.byBusinessSize.nonprofit",
                url: NGO_PAGE_LINK
            }
        ]
    },
    {
        id: BY_INDUSTRY_SECTION_ID,
        title: "header.tabs.solutions.byTeam.industries.title",
        titleLine: true,
        classname: MENU_SECTION_CLASSNAMES.useCases,
        maxNumberOfItemsInColumn: 4,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.byIndustry,
                icon: RetailColoredIcon,
                hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                title: "header.tabs.solutions.byTeam.industries.retail.title",
                url: RETAIL_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.byIndustry,
                icon: MediaColoredIcon,
                hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                title: "header.tabs.solutions.byTeam.industries.media.title",
                url: MEDIA_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.byIndustry,
                icon: ConstructionColoredIcon,
                hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                title: "header.tabs.solutions.byTeam.industries.construction.title",
                url: CONSTRUCTION_MINI_SITE_LINK
            }
        ]
    }
];
const featuresSectionMenuItems = [
    {
        id: KEY_FEATURES_SECTION_ID,
        maxNumberOfItemsInColumn: 2,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: DashboardsColoredIcon,
                title: "header.tabs.features.keyFeatures.dashboards.title",
                description: "header.tabs.features.keyFeatures.dashboards.description",
                outline: true,
                url: DASHBOARDS_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: IntegrationsColoredIcon,
                title: "header.tabs.features.keyFeatures.integrations.title",
                description: "header.tabs.features.keyFeatures.integrations.description",
                outline: true,
                url: INTEGRATIONS_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: AutomationsColoredIcon,
                title: "header.tabs.features.keyFeatures.automations.title",
                description: "header.tabs.features.keyFeatures.automations.description",
                outline: true,
                url: AUTOMATIONS_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: AIColoredIcon,
                title: "header.tabs.features.keyFeatures.ai.title",
                description: "header.tabs.features.keyFeatures.ai.description",
                outline: true,
                url: AI_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: KanbanColoredIcon,
                title: "header.tabs.features.keyFeatures.kanban.title",
                description: "header.tabs.features.keyFeatures.kanban.description",
                outline: true,
                url: KANBAN_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: DocsColoredIcon,
                title: "header.tabs.features.keyFeatures.docs.title",
                description: "header.tabs.features.keyFeatures.docs.description",
                outline: true,
                url: WORKDOCS_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: GanttColoredIcon,
                title: "header.tabs.features.keyFeatures.gantt.title",
                description: "header.tabs.features.keyFeatures.gantt.description",
                outline: true,
                url: GANTT_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.feature,
                icon: FormsColoredIcon,
                title: "header.tabs.features.keyFeatures.forms.title",
                description: "header.tabs.features.keyFeatures.forms.description",
                outline: true,
                url: FORMS_PAGE_LINK
            }
        ]
    }
];
const resourcesSectionMenuItems = [
    {
        id: RESOURCES_LEARN_SECTION_ID,
        classname: MENU_SECTION_CLASSNAMES.resources,
        title: "header.tabs.resources.section.learn",
        titleLine: true,
        noSeparator: true,
        maxNumberOfItemsInColumn: 5,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.learn.knowledgeBase.title",
                description: "header.tabs.resources.learn.knowledgeBase.description",
                url: HELP_CENTER_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.learn.whatsNew.title",
                description: "header.tabs.resources.learn.whatsNew.description",
                url: WHATS_NEW_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.learn.aboutUs.title",
                description: "header.tabs.resources.learn.aboutUs.description",
                url: ABOUT_US_PAGE_LINK
            },
            // {
            //   itemType: MENU_SECTION_ITEM_TYPE.resource,
            //   title: "header.tabs.resources.learn.resourcesHub.title",
            //   description: "header.tabs.resources.learn.resourcesHub.description",
            // },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.learn.blog.title",
                description: "header.tabs.resources.learn.blog.description",
                url: BLOG_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.connect.BuilderCertificationsText.title",
                description: "header.tabs.resources.connect.BuilderCertificationsText.description",
                url: BUILDERS_CERTIFICATIONS_PAGE_LINK
            }
        ]
    },
    {
        id: RESOURCES_CONNECT_SECTION_ID,
        title: "header.tabs.resources.section.connect",
        titleLine: true,
        noSeparator: true,
        maxNumberOfItemsInColumn: 5,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.connect.support.title",
                description: "header.tabs.resources.connect.support.description",
                url: SUPPORT_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.connect.partners.title",
                description: "header.tabs.resources.connect.partners.description1",
                url: PARTNERS_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.connect.globalEvents.title",
                description: "header.tabs.resources.connect.globalEvents.description",
                url: GLOBAL_EVENTS_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                hoverBackgroundColor: colors[BACKGROUND_GRAY_COLOR_NAME],
                title: "header.tabs.resources.connect.community.title",
                description: "header.tabs.resources.connect.community.description",
                url: COMMUNITY_LINK
            }
        ]
    },
    {
        id: RESOURCES_EXPLORE_SECTION_ID,
        title: "header.tabs.resources.section.build",
        titleLine: true,
        noSeparator: true,
        maxNumberOfItemsInColumn: 5,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.explore.appsDeveloper.title",
                description: "header.tabs.resources.explore.appsDeveloper.description",
                url: APPS_DEVELOPER_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.explore.templateCenter.title",
                description: "header.tabs.resources.explore.templateCenter.description1",
                url: TEMPLATE_CENTER_PAGE_LINK
            },
            {
                itemType: MENU_SECTION_ITEM_TYPE.resource,
                title: "header.tabs.resources.explore.spaces.title",
                description: "header.tabs.resources.explore.spaces.description",
                url: MONDAY_SPACES_PAGE_LINK
            }
        ]
    },
    {
        id: TOP_STORIES_SECTION_ID,
        title: "header.tabs.resources.section.customersStories",
        titleLine: true,
        noSeparator: true,
        maxNumberOfItemsInColumn: 2,
        backgroundColor: BACKGROUND_GRAY_COLOR_NAME,
        hideOnMobile: true,
        menuSectionItems: [
            {
                itemType: MENU_SECTION_ITEM_TYPE.story,
                hoverBackgroundColor: colors[WHITE_COLOR_NAME],
                imageSrc: "https://dapulse-res.cloudinary.com/image/upload/Generator_featured%20images/Home%20Page%20-%202022%20Rebrand/header/Header_asset.png",
                description: "header.tabs.resources.topStories.whyCustomersUse",
                url: CUSTOMERS_PAGE_LINK,
                button: true
            }
        ]
    }
];
/** * Structured Header sections definitions - End ** */ export const SectionsHeadersMenuItemsMapping = {
    [PRODUCTS_MENU_ID]: productsSectionMenuItems,
    [USE_CASES_MENU_ID]: useCasesSectionMenuItems,
    [FEATURES_MENU_ID]: featuresSectionMenuItems,
    [RESOURCES_MENU_ID]: resourcesSectionMenuItems
};
// ===========================================================================================
// Header service functions
export const isDesktopMinisiteSecondaryMenuActive = ()=>{
    const headerElement = document.querySelector(`.${DESKTOP_HEADER_BASE_CLASS_NAME}`);
    if (!headerElement) return false;
    return headerElement.classList.contains("scroll-started") && !headerElement.classList.contains("scrolled-threshold");
};
export const shouldRenderSecondaryMenu = (isClusterMiniSitePage, pageClusterConfig, useProductMiniSiteConfig)=>{
    return isClusterMiniSitePage && isMiniSiteConfigValid(pageClusterConfig, {
        useProductMiniSiteConfig
    });
};
